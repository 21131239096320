<template>
  <v-container
    id="panels"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        class="mx-auto"
        cols="12"
        md="12"
      >

       <base-material-tabs
          v-model="active_tab"
          color="warning"
          icons-and-text
        >
          <v-tab
                v-for="(tab, i) in tabs"
                :key="i"
              >
            {{ tab.text }}
            <v-icon v-text="tab.icon" />
          </v-tab>

          <v-tabs-items
            v-model="active_tab"
            class="pt-5 transparent"
          >
            <v-tab-item>
              <base-material-card
                icon="mdi-clipboard-text"
                title="List Data Case"
                class="px-5 py-3"
              >
                <v-row>
                  <v-col class="text-right">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn color="red" v-on="on" @click="showAddForm()">
                            <v-icon>mdi-plus</v-icon>Buat Case
                        </v-btn>
                      </template>
                      <span>Klik disini untuk Buat Case baru.</span>
                    </v-tooltip>
                    
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="listQuery.search"
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col>
                  <v-btn  small color="blue" @click="frombtnSearch()">Search</v-btn>
                  </v-col>
                </v-row>
                <v-spacer></v-spacer>
                <v-data-table
                    :headers="headers"
                    :items="listData"
                    :items-per-page="15"
                    class="elevation-1"
                    :server-items-length="totalDataCase"
                    :loading="listLoading"
                    :options.sync="tableOpt"
                  >

                  <template v-slot:item.subject="{ item }">
                    <div class="font-weight-medium">{{ item.subject }}</div>
                  </template>

                  <template v-slot:item.unit="{ item }">
                    <div class="font-weight-light">{{ item.unit }}</div>
                  </template>

                  <template v-slot:item.tglCase="{ item }">
                    <div class="caption font-weight-light">Created: {{ item.created_at | formatDatetime}}, by: <span class="caption font-italic font-weight-light">{{ item.createdby }}</span></div>
                    <div class="caption font-weight-light">Updated: {{ item.updated_at | formatDatetime}}, by: <span class="caption font-italic font-weight-light">{{ item.updatedby }}</span></div>
                  </template>

                  

                  <template v-slot:item.aksi="{ item }">

                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn color="green" v-on="on" fab x-small :to="'/case/view/'+item.caseGUID" >
                          <v-icon>mdi-eye</v-icon>
                        </v-btn>
                      </template>
                      <span>Buka Case</span>
                    </v-tooltip>
                    &nbsp;

                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn color="warning" v-on="on" fab x-small :to="'/case/edit/'+item.id" >
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <span>Ubah</span>
                    </v-tooltip>
      
                    &nbsp;

                    <v-tooltip top v-if="isAdmin">
                      <template v-slot:activator="{ on }">
                        <v-btn color="red" v-on="on" fab x-small @click="delData(item.id)">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>Hapus Data</span>
                    </v-tooltip>
                    
                  </template>
                </v-data-table>

                </base-material-card>
              </v-tab-item>

              <v-tab-item>
                <base-material-card
                  icon="mdi-clipboard-text"
                  title="Draft"
                  class="px-5 py-3"
                  >
                  <v-data-table
                      :headers="headers_draft"
                      :items="listDataDraft"
                      class="elevation-1"
                      @selected="getListDRAFT()"
                    >
                    <template v-slot:item.listTujuan="{ item }">
                      <span v-if="item.listTujuan" style="font-size:11px;">
                        <span v-if="item.listTujuan.length > 1">
                          <ul>
                            <li v-for="(itm,i) in item.listTujuan" :key="i">
                              <span v-if="itm.pos_name">{{itm.prefix + ' ' + itm.pos_name}} ({{itm.fullname}})</span>
                              <span v-else>{{itm}}</span>
                            </li>
                          </ul>
                        </span>
                        <span v-else-if="item.listTujuan.length == 1">
                          <span v-if="item.listTujuan[0].pos_name">{{item.listTujuan[0].prefix + ' ' + item.listTujuan[0].pos_name}} ({{item.listTujuan[0].fullname}})</span>
                          <span v-else>{{item.listTujuan[0]}}</span>
                        </span>
                        <span v-else>
                          <span>NA</span>
                        </span>
                      </span>
                    </template>

                    <template v-slot:item.tglSurat="{ item }">
                      <span style="font-size:12px;">{{ item.tglSurat | formatDate }}</span>
                    </template>
                    <template v-slot:item.aksi="{ item }">
                    
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn color="blue" v-on="on" fab x-small text @click="viewProcess(item)"  >
                            <v-icon>mdi-eye</v-icon>
                          </v-btn>
                        </template>
                        <span>Lihat Surat</span>
                      </v-tooltip>
                      <v-tooltip top v-if="item.badgeStatus.id != 6 && (isAdmin || item.badgeStatus.id != 19)">
                        <template v-slot:activator="{ on }">
                          <v-btn v-if="item.idmodel == 4 || item.idmodel == 5" fab x-small text @click="gotoEditor(item.letterGUID, item.idmodel)" v-on="on"  >
                            <v-icon class="orange--text pointer">mdi-cloud-print-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Ubah Surat Online</span>
                      </v-tooltip>
                      <v-tooltip top v-if="item.badgeStatus.id != 6 && (isAdmin || item.badgeStatus.id != 19)">
                        <template v-slot:activator="{ on }">
                          <v-btn color="orange" v-on="on" fab x-small text @click="editProcess(item.eolettertype.id, item.letterGUID, item.caseGUID)"  >
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <span>Ubah Header</span>
                      </v-tooltip>
                      <v-tooltip top v-if="item.signedSurat">
                        <template v-slot:activator="{ on }">
                          <a :href="item.signedSurat.linkfile" target="_blank" v-on="on">
                            <v-icon class="green--text pointer">mdi-file-pdf</v-icon>
                          </a>
                        </template>
                        <span>Lihat File</span>
                      </v-tooltip>
                      <v-tooltip top v-else-if="item.scanSurat">
                        <template v-slot:activator="{ on }">
                          <a :href="item.scanSurat.linkfile" target="_blank" v-on="on">
                            <v-icon class="green--text pointer">mdi-file-pdf</v-icon>
                          </a>
                        </template>
                        <span>Lihat File</span>
                      </v-tooltip>
                      <v-tooltip top v-else >
                        <template v-slot:activator="{ on }">
                          <a href="#" v-on="on">
                            <v-icon class="red--text pointer">mdi-file-pdf</v-icon>
                          </a>
                        </template>
                        <span>File Surat belum diUpload / Selesai diTTD</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn color="red" v-on="on" fab x-small text @click="cancelSurat(item.id)"  >
                            <v-icon>mdi-cancel</v-icon>
                          </v-btn>
                        </template>
                        <span>Batalkan</span>
                      </v-tooltip>
                      <v-tooltip top v-if="isAdmin">
                        <template v-slot:activator="{ on }">
                          <v-btn color="red" v-on="on" fab x-small text @click="delData(item.id)"  >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </template>
                        <span>Hapus Surat</span>
                      </v-tooltip>
                    </template>
                  </v-data-table>

                </base-material-card>
              </v-tab-item>
              <v-tab-item>
                <base-material-card
                  icon="mdi-clipboard-text"
                  title="Proses Persetujuan TTD"
                  class="px-5 py-3"
                  >
                  <v-data-table
                      :headers="headers_persetujuan"
                      :items="listDataPersetujuan"
                      class="elevation-1"
                      @selected="getListPersetujuan()"
                    >
                    <template v-slot:item.penandatangan="{ item }">
                        <v-btn style="cursor: none;" v-if="item.badgeStatus" :color="item.badgeStatus.color" x-small>{{item.badgeStatus.text}}</v-btn><br>
                        &nbsp;<span>{{ item.penandatanganName }}</span><br>
                        <span style="font-size:11px;">Jabatan: {{ item.penandatanganPosPrefix + ' ' + item.penandatanganPos}} </span>
                      </template>

                    <template v-slot:item.tglSurat="{ item }">
                      <span style="font-size:12px;">{{ item.created_at | formatDatetime }}</span>
                    </template>
                    <template v-slot:item.aksi="{ item }">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn color="blue" v-on="on" fab x-small text @click="viewSurat(item)"  >
                            <v-icon>mdi-eye</v-icon>
                          </v-btn>
                        </template>
                        <span>Lihat Surat</span>
                      </v-tooltip>
                      <v-tooltip top v-if="item.eostatus == 5">
                        <template v-slot:activator="{ on }">
                          <v-btn color="red" v-on="on" fab x-small text @click="cancelSurat(item.datasurat)"  >
                            <v-icon>mdi-cancel</v-icon>
                          </v-btn>
                        </template>
                        <span>Batalkan</span>
                      </v-tooltip>
                      
                    </template>
                  </v-data-table>

                </base-material-card>
              </v-tab-item>

            </v-tabs-items>
        
        </base-material-tabs>  
        
      </v-col>
    </v-row>

    <v-snackbar
      v-model="toast.show"
      :timeout="toast.timeout"
    >
      {{ toast.text }}
      <v-btn
        :color="toast.color"
        text
        @click="toast.show = false"
      >
        Close
      </v-btn>
    </v-snackbar>

    <v-dialog v-model="isLoading" fullscreen>
          <v-container fluid fill-height style="background-color: rgba(0, 0, 0, 0.8);">
              <v-layout justify-center align-center>
                <v-card
                  width="70vW"
                  class="pa-md-2 mx-lg-auto"
                  outlined
                >
                  <v-list-item three-line centered>
                    <v-list-item-content>
                      <div class="overline mb-4">Processing</div>
                      <v-list-item-title class="headline mb-1">Please Wait....</v-list-item-title>
                      <v-list-item-subtitle>
                        <v-progress-linear
                          indeterminate
                          color="yellow darken-2"
                        ></v-progress-linear>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>

              </v-layout>
          </v-container>
      </v-dialog>

    <v-dialog v-model="delConfirm" persistent max-width="350">
        <v-card>
          <v-card-title class="headline">Konfirmasi</v-card-title>
          <v-card-text>Apakah anda yakin ingin meng-HAPUS data tersebut?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning darken-1" text @click="delConfirm = false">Batal</v-btn>
            <v-btn color="blue darken-1" @click="doDeleteData()">Hapus</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

       <v-dialog v-model="cancelConfirm" persistent max-width="350">
        <v-card>
          <v-card-title class="headline">Konfirmasi</v-card-title>
          <v-card-text>Apakah anda yakin ingin membatalkan surat tersebut?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning darken-1" text @click="cancelConfirm = false">Tutup</v-btn>
            <v-btn color="red darken-1" @click="doCancelData()">Batalkan Surat</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

  </v-container>
</template>

<script>
  import moment from 'moment-timezone'
  import secureStorage from '@/utils/secureStorage'
  import { splitComponentsByComma } from '@/utils/index'

  import { deleteCaseSurat, getCaseSurat, countCaseSurat, getCaseSuratSearchfromSurat, getCaseSuratByNameKonseptor, countCaseSuratByNameKonseptor } from '@/api/casesurat'
  import { deleteDataSurat, updateDataSurat, printSuratbyGUID, getSuratfromCase, countSKDraft, getSKDraft } from '@/api/datasurat'
  import { getListLetterTypesActive } from '@/api/lettertypes'
  import { generatePenomoranSrt, deleteReversingPenomoranSrt } from '@/api/penomoransrt'
  import { getLastPenandatanganApproval, updateApproval, getSMTTDstaff, countSMTTDstaff } from '@/api/letterapproval'
  import { _GLOBAL } from '@/api/server'

  export default {
    name: 'ListCaseSurat',

    data: () => ({
      active_tab: 1,
      toast: { show: false, color:'green', text:'', timeout: 2000 },
      tabs: [
        {
          text: 'CASE SURAT',
          icon: 'mdi-information',
        },
        {
          text: 'DRAFT SURAT',
          icon: 'mdi-send',
        },
        {
          text: 'PERSETUJUAN SURAT',
          icon: 'mdi-timer-sand',
        }
      ],
      headers: [
        {
          text: 'ID',
          align: 'start',
          filterable: false,
          value: 'id',
          width:'100', 
          class:'subtitle-1'
        },
        { text: 'Perihal', width:'30%', value: 'subject', align: 'left', class:'subtitle-1'},
        { text: 'Unit', value: 'unit', width:'20%', align: 'center', sortable: true, class:'subtitle-1' },
        { text: 'Tgl', value: 'tglCase', align: 'left', sortable: true, class:'subtitle-1' },
        { text: 'Aksi', width:'150', value: 'aksi', sortable: false, align: 'center', class:'subtitle-1'}
      ],
      headers_draft: [
        {
          text: 'ID',
          align: 'start',
          value: 'id',
          width:'30', 
          class:'subtitle-1'
        },
        { text: 'Jenis Surat', width:'70',  value: 'jenisSurat', align: 'left', class:'subtitle-1'},
        { text: 'Perihal Surat',  value: 'perihalSurat', align: 'left', class:'subtitle-1'},
        { text: 'Tujuan Surat', value: 'listTujuan', align: 'left', class:'subtitle-1'},
        { text: 'Tgl', value: 'tglSurat', width:'100', align: 'center', sortable: true, class:'subtitle-1' },
        { text: 'Aksi', width:'150', value: 'aksi',  sortable: false, align: 'center', class:'subtitle-1'}
      ],
      headers_persetujuan: [
        {
          text: 'ID Approval',
          align: 'center',
          value: 'id',
          width:'40', 
          class:'subtitle-1'
        },
        { text: 'Jenis Surat', width:'70', value: 'jenisSurat', align: 'left', class:'subtitle-1'},
        { text: 'Perihal Surat',  value: 'perihalSurat', align: 'left', class:'subtitle-1'},
        { text: 'Penandatangan', value: 'penandatangan', align: 'left', class:'subtitle-1'},
        { text: 'Tgl', value: 'tglSurat', width:'100', align: 'center', sortable: true, class:'subtitle-1' },
        { text: 'Aksi', width:'100', value: 'aksi',  sortable: false, align: 'center', class:'subtitle-1'}
      ],
      isLoading: false,
      selectedMonth: new Date().toISOString().substr(0, 7),
      menuSelMonth: false,
      delConfirm: false,
      cancelConfirm: false,
      idDel: 0,
      listData: [],
      totalDataCase: 0,
      totalDataDraft: 0,
      totalDataPersetujuan: 0,
      listLoading: false,
      tableOpt: {},
      listQuery: {
        page: 1,
        limit: 100,
        search: ''
      },
      listDataDraft: [],
      listDataPersetujuan: [],
      isAdmin: false,
      currrole: null,
      arrLetterTypes: [],
      filterParam:'hrorganizations',
      filterID: 0,
      searchClick: false,
      idCancel: 0
 
    }),
    filters: {
      formatDate: function (value) {
        if (!value) return ''
        var sdate = moment(value).add(7, 'hours').format('DD MMM YYYY')
        return sdate
      },
      formatDatetime: function (value) {
        if (!value) return ''
        var sdate = moment(value).add(7, 'hours').format('DD/MM/YYYY HH:mm')
        return sdate
      }
    },
    computed: {
    },
    created () {
      const vm = this
      const idtab = this.$route.params && this.$route.params.idtab
      vm.active_tab = parseInt(idtab)
      // console.log(vm.active_tab)
     
      vm.currrole = secureStorage.getItem('currRole')
      console.log(vm.currrole)
      if(vm.currrole){
        if(vm.currrole.rolename == 'admin'){
          vm.isAdmin = true
        }
      }

      vm.getLetterTypes()
    
    },
    destroyed () {
    },
    mounted () {
      this.getList()
      this.getListDRAFT()
      this.getListPersetujuan()
    },
    watch: {
      tableOpt: {
        handler () {
          if(!this.listLoading) this.refresh()
        },
        deep: true,
      },
    },
    methods: {
      goFileLink (uri) {
        var open = window.open(uri,'_blank')
        if (!open || open == null || typeof(open)=='undefined'){
          var errmsg = "Turn off your pop-up blocker!\n\nWe try to open the following url:\n"+url 
          console.log(errmsg)
          vm.$alert(errmsg)
        }
      },
      gotoEditor(letterGUID, model){
        if(model == 4){
          this.$router.push('/case/editord/' + letterGUID )
        }else if(model == 5){
          this.$router.push('/case/editore/' + letterGUID )
        }else {
          // console.log('model surat not detected')
        }
        
      },
      refresh() {
        const vm = this
        if(vm.active_tab == 0){
          vm.getList()
        }else if(vm.active_tab == 1){
          vm.getListDRAFT()
        }else if(vm.active_tab == 2){
          vm.getListPersetujuan()
        }
      },
      showAddForm () {
        const vm = this
        vm.$router.push('/case/create')
      },
      filterData() {
        const vm = this
        var user = secureStorage.getItem('user')
       
        if(vm.active_tab == 0){
          vm.getList()
        } else if(vm.active_tab == 1){
          vm.getListDRAFT()
        }
        // console.log('filterID: ', vm.filterID)

      },
      getLetterTypes(){
        const vm = this
        vm.isLoading = true
        getListLetterTypesActive().then(response => {
          var data = response.data
          vm.arrLetterTypes = data
          vm.isLoading = false
        }).catch(err => {
          // console.log(err)
          vm.isLoading = false
        })
      },
      frombtnSearch(){
        this.searchClick = true
        this.getList()
        
      },
      async getList() {

        const vm = this
        if(vm.currrole == null) return false
        console.log(vm.currrole)

        var user = secureStorage.getItem('user')
        if(user == null) return false

        var dataUserAll = secureStorage.getItem('userall')
        // console.log('dataUserAll', dataUserAll)
        if(dataUserAll == null) return false

        const { sortBy, sortDesc, page, itemsPerPage } = vm.tableOpt
        vm.listQuery.page = page
        vm.listQuery.limit = itemsPerPage
        vm.listQuery.limit = itemsPerPage == 1000 ? vm.totalDataCase : itemsPerPage
        // console.log('listQuery: ',vm.listQuery)
        // console.log('filterSearch: ',vm.filterSearch)
        // console.log('tableOpt', vm.tableOpt)
        // get total data
        if(this.searchClick){
          vm.listQuery.page = 1
          this.searchClick = false
        }

        var userposition = vm.currrole.id
        var hrorganization = vm.currrole.hrorganization.id
        var rolename = vm.currrole.rolename
        var holding = vm.currrole.hrorganization.holding
        var hrorg_level = vm.currrole.hrorganization.level
        var unit_code = vm.currrole.hrorganization.unit_code
        unit_code = unit_code.toString()

        var pos_name = vm.currrole.hrposition.pos_name

        //console.log('rolename, hrorg_level, pos_name', rolename, hrorg_level, pos_name)
        //console.log('unit_code', unit_code)

        var ucode_filtered = unit_code
        if(rolename == 'sekretaris' && (hrorg_level == 'Main_PD' || hrorg_level == 'Main_VPD')){
          ucode_filtered = unit_code.substr(0, 2)
          console.log('1')
        }else if(rolename == 'sekretaris' && hrorg_level == 'Direktorat'){
          ucode_filtered = unit_code.substr(0, 4)
          console.log('2')
        }else if(rolename == 'sekretaris' && hrorg_level == 'Biro'){
          ucode_filtered = unit_code.substr(0, 5)
          console.log('3')
        }else if(rolename == 'komite'){
          ucode_filtered = unit_code.substr(0, 2)
          console.log('4')
        }else if( hrorg_level == 'Main_PD' || hrorg_level == 'Main_VPD'){
          ucode_filtered = unit_code.substr(0, 2)
          console.log('5')
        }else if( hrorg_level == 'Direktorat'){
          ucode_filtered = unit_code.substr(0, 4)
          console.log('6')
        }else if( hrorg_level == 'Biro'){
          ucode_filtered = unit_code.substr(0, 5)
          console.log('7')
        }else if( hrorg_level == 'Departement'){
          ucode_filtered = unit_code.substr(0, 6)
          console.log('8')
        }else if( hrorg_level == 'Sub_Departement'){
          ucode_filtered = unit_code.substr(0, 8)
          console.log('9')
        }else {
          ucode_filtered = unit_code.substr(0, 9)
          console.log('10')
        }
        console.log('getList CaseSurat', ucode_filtered)

        vm.listLoading = true
        // console.log(vm.currrole.rolename)
        countCaseSurat(vm.listQuery, userposition, hrorganization, rolename, holding, hrorg_level, ucode_filtered).then(response => {
          var data = response.data
          console.log('totdata', data[0].total)

          vm.totalDataCase = parseInt(data[0].total)
          vm.listLoading = false
        })
        
        // get list of data
        vm.listLoading = true
        var filtereddata = []
        // console.log('dataUserAll', dataUserAll)
        getCaseSurat(vm.listQuery, userposition, hrorganization, rolename, holding, hrorg_level, ucode_filtered).then(response => {
          var array = response.data
          // console.log(array)
          array.forEach(element => {
            
            element['unit'] = element.hrorganizations_level + ' ' + element.hrorganizations_structure_name 
            
            element['createdby'] = '-'
            if(element.userCreator){
              //console.log(element.userCreator)
              var arrUserCreated = dataUserAll.find(x => x.id === element.userCreator)
              if(arrUserUpdated){
                element['createdby'] = arrUserCreated.fullname 
              }
            }

            element['updatedby'] = '-'
            if(element.userEditor){
              // console.log(element.userEditor)
              var arrUserUpdated = dataUserAll.find(x => x.id === element.userEditor)
              if(arrUserUpdated){
                element['updatedby'] = arrUserUpdated.fullname 
              }
            }
            // console.log(element)

            filtereddata.push(element)
          })

          if (sortBy && sortDesc && sortBy.length === 1 && sortDesc.length === 1) {
            filtereddata = filtereddata.sort((a, b) => {
            const sortA = a[sortBy[0]]
            const sortB = b[sortBy[0]]

            if (sortDesc[0]) {
              if (sortA < sortB) return 1
              if (sortA > sortB) return -1
              return 0
            } else {
              if (sortA < sortB) return -1
              if (sortA > sortB) return 1
              return 0
            }
          })
        }
          vm.listData = filtereddata
          // console.log('listData 2', vm.listData)
          vm.listLoading = false
        })

      },
      
      getListDRAFT() {
        const vm = this
        // console.log('getListDRAFT')

        if(vm.currrole == null) return false

        const { sortBy, sortDesc, page, itemsPerPage } = vm.tableOpt
        vm.listQuery.page = page
        vm.listQuery.limit = itemsPerPage
        if(!itemsPerPage) {
          vm.listQuery.page = 1
          vm.listQuery.limit = 15
        }else {
          // console.log('itemsPerPage: ',itemsPerPage)
          vm.listQuery.limit = itemsPerPage == -1 ? vm.totalDataDraft : itemsPerPage
        }
        // console.log('listQuery: ',vm.listQuery)
        // get total data
        vm.listLoading = true
        countSKDraft(vm.listQuery, vm.currrole.id).then(response => {
          // console.log('countSKDraft', response.data)
          var data = response.data
          vm.totalDataDraft = parseInt(data)
          // console.log(vm.totalDataDraft)
          vm.listLoading = false
        })
        // get list of data
        vm.listLoading = true
        getSKDraft(vm.listQuery, vm.currrole.id).then(response => {
          // console.log('getSKDraft', response.data)
          var array = response.data
          var users = secureStorage.getItem('userall')
          var posall = secureStorage.getItem('posall')
          array.forEach(element => {
            // console.log(element)
            element['unit'] = '-'
            if(element.hrorganizations){
              element['unit'] = element.hrorganizations.level + ' ' + element.hrorganizations.structure_name 
            }

            if(!element.subject){
              element['subject'] = element.subject
            }

            element['listTujuan'] = '-'
            if(element.tujuanSurat){
              var arrTujuan = []
              var datauserposall = secureStorage.getItem('userposall')
              let tos = element.tujuanSurat
              if(tos){
                tos = tos.toString()
                // let arrtos =splitComponentsByComma(tos)
                let arrtos = tos.split('|')
                if(arrtos && arrtos.length > 0 && datauserposall.length > 0){
                  arrtos.forEach(idtos => {
                    let isIntid =  parseInt(idtos)
                    if(isIntid > 0){
                      let tmp = datauserposall.find(x => x.id === isIntid)
                      if(tmp){
                        arrTujuan.push(tmp)
                      }
                    }else{
                      if(idtos != ''){
                        arrTujuan.push(idtos)
                      }
                    }
                  })
                }
              }
              element['listTujuan'] = arrTujuan
            }

            if(element.eolettertype){
              var prefixSurat = element.eolettertype.prefix
              prefixSurat = prefixSurat.replace('-','')
              element['jenisSurat'] = prefixSurat
            }
            
            element['createdby'] = '-'
            if(element.userCreator){
              // var arrname = vm.dataUserAll.find(x => x.id === element.hruserpositions_createdBy.user)
              element['createdby'] = element.userCreator.fullname 
            }

            element['updatedby'] = '-'
            if(element.userEditor){
              // var arrname = vm.dataUserAll.find(x => x.id === element.hruserpositions_editedBy.user)
              element['updatedby'] = element.userEditor.fullname 
            }

            if(!element.noSurat){
              element['noSurat'] = '-'
            }
            element['noid'] = '#' + element.id

            element['idmodel'] = 0
            if(element.eolettertype){
              element['idmodel'] = parseInt(element.eolettertype.modelsurat)
            }

            if(element.signedSurat){
              var tmp = {linkfile: _GLOBAL.URLBACKEND + element.signedSurat.url}
              var obj = element.signedSurat
              element.signedSurat = Object.assign(tmp, obj);
            }

            if(element.scanSurat){
              var tmp = {linkfile: _GLOBAL.URLBACKEND + element.scanSurat.url}
              var obj = element.scanSurat
              element.scanSurat = Object.assign(tmp, obj);
            }

            element['badgeStatus'] = { text:'', color: 'white' , id: 0}
            if(element.statusSurat){
              if(element.statusSurat.id == 11){
                element['badgeStatus'] = { text: element.statusSurat.status_name, color: 'grey' , id: element.statusSurat.id}
              }else if(element.statusSurat.id == 3){
                element['badgeStatus'] = { text: element.statusSurat.status_name, color: 'blue', id: element.statusSurat.id}
              }else if(element.statusSurat.id == 4 || element.statusSurat.id == 19){
                element['badgeStatus'] = { text: element.statusSurat.status_name, color: 'green', id: element.statusSurat.id}
              }else if(element.statusSurat.id == 6){
                element['badgeStatus'] = { text: element.statusSurat.status_name, color: 'red', id: element.statusSurat.id}
              } else {
                element['badgeStatus'] = { text: element.statusSurat.status_name, color: 'grey', id: element.statusSurat.id}
              }
            }
          })

          if (sortBy && sortDesc && sortBy.length === 1 && sortDesc.length === 1) {
            array = array.sort((a, b) => {
              const sortA = a[sortBy[0]]
              const sortB = b[sortBy[0]]

              if (sortDesc[0]) {
                if (sortA < sortB) return 1
                if (sortA > sortB) return -1
                return 0
              } else {
                if (sortA < sortB) return -1
                if (sortA > sortB) return 1
                return 0
              }
            })
          }

          vm.listDataDraft = array
          // console.log('listDataDraft', vm.listDataDraft)
          vm.listLoading = false
        })
      },
      getListPersetujuan() {
        const vm = this
        // console.log('getListPersetujuan')

        if(vm.currrole == null) return false

        const { sortBy, sortDesc, page, itemsPerPage } = vm.tableOpt
        vm.listQuery.page = page
        vm.listQuery.limit = itemsPerPage
        if(!itemsPerPage) {
          vm.listQuery.page = 1
          vm.listQuery.limit = 15
        }else {
          // console.log('itemsPerPage: ',itemsPerPage)
          vm.listQuery.limit = itemsPerPage == -1 ? vm.totalDataPersetujuan : itemsPerPage
        }
        // console.log('listQuery: ',vm.listQuery)
        // get total data
        vm.listLoading = true
        countSMTTDstaff(vm.currrole.id).then(response => {
          // console.log('countSMTTDstaff', response.data)
          var data = response.data
          vm.totalDataPersetujuan = parseInt(data)
          // console.log(vm.totalDataPersetujuan)
          vm.listLoading = false
        })
        // get list of data
        vm.listLoading = true
        getSMTTDstaff(vm.currrole.id).then(response => {
          // console.log('getSMTTDstaff', response.data)
          var array = response.data
          var users = secureStorage.getItem('userall')
          var posall = secureStorage.getItem('posall')
          array.forEach(element => {

            element['unit'] = '-'
            if(element.hrorganizations){
              element['unit'] = element.hrorganizations.level + ' ' + element.hrorganizations.structure_name 
            }

            if(!element.subject){
              element['subject'] = element.subject
            }

            element['listTujuan'] = '-'
            if(element.tujuanSurat){
              var arrTujuan = []
              var datauserposall = secureStorage.getItem('userposall')
              // console.log('datauserposall',datauserposall)
              let tos = element.tujuanSurat
              if(tos){
                tos = tos.toString()
                // let arrtos =splitComponentsByComma(tos)
                let arrtos = tos.split('|')
                if(arrtos && datauserposall && arrtos.length > 0 && datauserposall.length > 0){
                  arrtos.forEach(idtos => {
                    let isIntid =  parseInt(idtos)
                    if(isIntid > 0){
                      let tmp = datauserposall.find(x => x.id === isIntid)
                      if(tmp){
                        arrTujuan.push(tmp)
                      }
                    }else{
                      if(idtos != ''){
                        arrTujuan.push(idtos)
                      }
                    }
                  })
                }
              }
              element['listTujuan'] = arrTujuan
            }

            if(element.eolettertype){
              var prefixSurat = element.eolettertype
              prefixSurat = prefixSurat.replace('-','')
              element['jenisSurat'] = prefixSurat
            }
            
            element['createdby'] = '-'
            if(element.userCreator){
              // var arrname = vm.dataUserAll.find(x => x.id === element.hruserpositions_createdBy.user)
              element['createdby'] = element.userCreator.fullname 
            }

            element['updatedby'] = '-'
            if(element.userEditor){
              // var arrname = vm.dataUserAll.find(x => x.id === element.hruserpositions_editedBy.user)
              element['updatedby'] = element.userEditor.fullname 
            }

            if(!element.noSurat){
              element['noSurat'] = '-'
            }
            element['noid'] = '#' + element.id

            element['idmodel'] = 0
            if(element.eolettertype){
              element['idmodel'] = parseInt(element.eolettertype.modelsurat)
            }

            if(element.signedSurat){
              var tmp = {linkfile: _GLOBAL.URLBACKEND + element.signedSurat.url}
              var obj = element.signedSurat
              element.signedSurat = Object.assign(tmp, obj);
            }

            if(element.scanSurat){
              var tmp = {linkfile: _GLOBAL.URLBACKEND + element.scanSurat.url}
              var obj = element.scanSurat
              element.scanSurat = Object.assign(tmp, obj);
            }

            element['badgeStatus'] = { text:'', color: 'white' , id: 0}
            if(element.eostatus){
              if(element.eostatus == 11){
                element['badgeStatus'] = { text: element.eostatusname, color: 'grey' , id: element.eostatus}
              }else if(element.eostatus == 3){
                element['badgeStatus'] = { text: element.eostatusname, color: 'blue', id: element.eostatus}
              }else if(element.eostatus == 4 || element.eostatus == 19){
                element['badgeStatus'] = { text: element.eostatusname, color: 'green', id: element.eostatus}
              }else if(element.eostatus == 6){
                element['badgeStatus'] = { text: element.eostatusname, color: 'red', id: element.eostatus}
              } else {
                element['badgeStatus'] = { text: element.eostatusname, color: 'grey', id: element.eostatus}
              }
            }
          })

          if (sortBy && sortDesc && sortBy.length === 1 && sortDesc.length === 1) {
            array = array.sort((a, b) => {
              const sortA = a[sortBy[0]]
              const sortB = b[sortBy[0]]

              if (sortDesc[0]) {
                if (sortA < sortB) return 1
                if (sortA > sortB) return -1
                return 0
              } else {
                if (sortA < sortB) return -1
                if (sortA > sortB) return 1
                return 0
              }
            })
          }

          vm.listDataPersetujuan = array
          // console.log('listDataPersetujuan', vm.listDataPersetujuan)
          vm.listLoading = false
        })
      },
      editProcess (kdsurat, lGUID, caseGUID) {
        const vm = this
        if(!lGUID || lGUID == '') {
          vm.toast = {
            show: true, color:'red', text: 'letterGUID null, silakan kontak IT Admin', timeout: 2000
          }
        }
        // console.log(kdsurat, lGUID)
        
        var arrSelected = vm.arrLetterTypes.find(x => x.id === kdsurat)
        // console.log(arrSelected)
        if(arrSelected){
          if(arrSelected.modelsurat){
            if(arrSelected.modelsurat.id == 1){
              vm.$router.push('/case/updlettera/' + caseGUID + '/' + kdsurat + '/' + lGUID)
            }else if(arrSelected.modelsurat.id == 2){
              vm.$router.push('/case/updletterb/' + caseGUID + '/' + kdsurat + '/' + lGUID)
            }else if(arrSelected.modelsurat.id == 3){
              vm.$router.push('/case/updletterc/' + caseGUID + '/' + kdsurat + '/' + lGUID)
            }else if(arrSelected.modelsurat.id == 4){
              vm.$router.push('/case/updletterd/' + caseGUID + '/' + kdsurat + '/' + lGUID)
            }else if(arrSelected.modelsurat.id == 5){
              vm.$router.push('/case/updlettere/' + caseGUID + '/' + kdsurat + '/' + lGUID)
            }else{
              vm.toast = {
                show: true, color:'red', text: 'Model Surat OUT OF RANGE pada Jenis Surat, silakan kontak IT Admin', timeout: 2000
              }
            }
          }else{
            vm.toast = {
              show: true, color:'red', text: 'Model Surat NULL pada Jenis Surat, silakan kontak IT Admin', timeout: 2000
            }
          }
        }else{
          vm.toast = {
            show: true, color:'red', text: 'Pilih Jenis Surat terlebih dahulu', timeout: 2000
          }
        }

      },
      viewProcess (dsurat) {
        const vm = this
        if(!dsurat.scanSurat) {
          vm.toast = {
            show: true, color:'red', text: 'File Softcopy Surat (PDF) belum di Upload, silakan klik menu Ubah untuk mengupload Softcopy Surat terlebih dahulu.', timeout: 2000
          }
          return false
        }

        if(!dsurat.letterGUID || dsurat.letterGUID == '') {
          vm.toast = {
            show: true, color:'red', text: 'letterGUID null, silakan kontak IT Admin', timeout: 2000
          }
          return false
        }

        if(dsurat.eosecuritytype.id == 1 || vm.currrole.rolename == 'komite' || vm.currrole.rolename == 'VP' || vm.currrole.rolename == 'presdir' || vm.currrole.rolename == 'admin' || vm.currrole.rolename == 'sekretaris'  ||  vm.dataUser.id == dsurat.userCreator.id || vm.dataUser.id == dsurat.userEditor.id){
          var uri = '/#/surat/v/' + dsurat.letterGUID
          var open = window.open(uri,'_blank')
          if (!open || open == null || typeof(open)=='undefined'){
            var errmsg = "Turn off your pop-up blocker!\n\nWe try to open the following url:\n"+uri
            console.log(errmsg)
            vm.$alert(errmsg)
          }
        }else{
          vm.toast = {
            show: true, color:'red', text: 'Surat bersifat RAHASIA, hanya dapat dibuka oleh yang Pemilik / Konseptor!', timeout: 2000
          }
          return false
        }

      },
      viewSurat (dsurat) {
        const vm = this

        if(!dsurat.letterGUID || dsurat.letterGUID == '') {
          vm.toast = {
            show: true, color:'red', text: 'letterGUID null, silakan kontak IT Admin', timeout: 2000
          }
          return false
        }

        var uri = '/#/surat/v/' + dsurat.letterGUID
        var open = window.open(uri,'_blank')
        if (!open || open == null || typeof(open)=='undefined'){
          var errmsg = "Turn off your pop-up blocker!\n\nWe try to open the following url:\n"+uri
          console.log(errmsg)
          vm.$alert(errmsg)
        }

      },

      delData (id) {
        const vm = this
        vm.idDel = id
        vm.delConfirm = true
      },
      doDeleteData () {
        const vm = this
        vm.delConfirm = false
        if (vm.idDel == 0) {
          return false
        }
        vm.isLoading = true
        // console.log('deleteData: ',vm.idDel)
        deleteCaseSurat(vm.idDel).then(response => {
          var data = response.data
          // console.log(data)
          vm.isLoading = false
          vm.getList()
          vm.toast = {
            show: true, color:'green', text: 'Data berhasil terhapus.', timeout: 2000
          }
        }).catch(err => {
          // console.log(err)
          vm.isLoading = false
           vm.toast = {
            show: true, color:'red', text: 'Data GAGAL terhapus, silakan dicoba kembali.', timeout: 2000
          }
        })
      },
      cancelSurat(ids){
        const vm = this
        vm.cancelConfirm = true
        vm.idCancel = ids
      },
      doCancelData(){
        const vm = this
        vm.cancelConfirm = false
        var data = {
          statusSurat: 30
        }
        updateDataSurat(data, vm.idCancel).then(resp1 => {
          var rData = resp1.data
          // console.log(rData)
          vm.toast = {
            show: true, color:'green', text: 'Surat BERHASIL di-Batalkan.', timeout: 2000
          }
          vm.refresh()
        }).catch(function(err){
          // console.log(err)
          vm.isLoading = false
        })
      }
    }
  }
</script>

import request from '@/utils/request'
import { _GLOBAL } from '@/api/server'
import secureStorage from '@/utils/secureStorage'
import moment from 'moment'

/** API CaseSurat */
export function getListDataSurat(listQuery, searchParam, caseguid) {
  // console.log(listQuery)
  var page = listQuery.page ? listQuery.page : 15
  var limit = listQuery.limit ? listQuery.limit : 1000
  var search = listQuery.search
  // console.log(limit)

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var start = (page - 1) * limit
  if (start < 0) start = 0

  var uri = _GLOBAL.URLBACKEND + '/datasurats?caseGUID=' + caseguid + '&'+ searchParam + '_contains=' + search + '&deleted=0&_limit=' + limit + '&_start=' + start + '&_sort=id%3ADESC'
  var data = listQuery
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function getListDataSuratNew(listQuery, caseguid, userposition, hrorganization, rolename) {
  // console.log(listQuery)
  var page = listQuery.page ? listQuery.page : 15
  var limit = listQuery.limit ? listQuery.limit : 1000
  // console.log(limit)

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var start = (page - 1) * limit
  if (start < 0) start = 0

  var uri = _GLOBAL.URLBACKEND + '/datasurats/f/listsurat?caseGUID=' + caseguid + '&limit=' + limit + '&userposition=' + userposition + '&hrorganization=' + hrorganization + '&rolename=' + rolename 
  var data = listQuery
  console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function getletterGUID(id_letter) {
  // console.log(getletterGUID)
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var uri = _GLOBAL.URLBACKEND + '/datasurats?id=' + id_letter +'&deleted=0'
  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function checkPdfEsignPosition(letterGUID) {
  // console.log(getletterGUID)
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var uri = _GLOBAL.URLBACKEND + '/datasurats/f/getposttd/' + letterGUID
  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function printSuratbyGUID(model, letterGUID) {
  // console.log(getletterGUID)
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  if(model == 4){
    var uri = _GLOBAL.URLBACKEND + '/datasurats/process_genpdf/' + letterGUID 
  }else{
    // console.log('modelsurat not found')
    return false
  }
  
  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}


export function printAndBSSNProcessbyGUID(data) {
  // console.log(getletterGUID)
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var uri = _GLOBAL.URLBACKEND + '/datasurats/procpdfnbssn' 
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'post',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function getletterSearch(search) {
  // console.log(getletterGUID)
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var uri = _GLOBAL.URLBACKEND + '/datasurats?noSurat_contains=' + search+'&deleted=0'
  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}



export function countListDataSurat(listQuery, searchParam, caseguid) {
  var search = listQuery.search

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  var data = ''

  var uri = _GLOBAL.URLBACKEND + '/datasurats/count?caseGUID=' + caseguid + '&'+ searchParam + '_contains=' + search+'&deleted=0'
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function getSuratfromCase(listQuery, par, filter, idfilter) {
  // console.log(listQuery)
  var page = listQuery.page ? listQuery.page : 15
  var limit = listQuery.limit ? listQuery.limit : 1000
  var search = listQuery.search
  // console.log(limit)

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var start = (page - 1) * limit
  if (start < 0) start = 0

  var uri = _GLOBAL.URLBACKEND + '/datasurats?'+ par + '=' + search + '&_limit=' + limit
  var data = listQuery
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function getDataSuratByLetterGUID(guid) {
  //console.log('getDataSuratByLetterGUID')
  if (guid <= 0) return null

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  
  var uri = _GLOBAL.URLBACKEND + '/datasurats?letterGUID=' + guid + '&_limit=1'+'&deleted=0'
  var data = ''
  var myeaders= {
    'Content-Type': 'application/json; charset=utf-8',
    'Authorization': `Bearer ${token}`
  }
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: myeaders,
    data
  })
  // console.log(myeaders)
  return response
}

export function insertDataSurat(data) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  if (!data) return null
  // console.log(data)

  var uri = _GLOBAL.URLBACKEND + '/datasurats'
  var response = request({
    url: uri,
    method: 'post',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  }).catch((err) => {
    return { 'id': 0, 'data': '', 'status': 'Data should be a unique please recheck the data.' }
  })
  // console.log(response)
  return response
}

export function deleteDataSurat(id) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  
  var data = {
    deleted: 1
  }
  var uri = _GLOBAL.URLBACKEND + '/datasurats/' + id
  var response = request({
    url: uri,
    method: 'put',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function deleteFileSurat(id) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  
  var data = {
    deleted: 1
  }
  var uri = _GLOBAL.URLBACKEND + '/upload/files/' + id
  var response = request({
    url: uri,
    method: 'delete',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function updateDataSurat(data, id) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  if (!data) return null

  var uri = _GLOBAL.URLBACKEND + '/datasurats/' + id
  var response = request({
    url: uri,
    method: 'put',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function deleteFileScanSurat(id) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  
  var data = {
    deleted: 1
  }
  var uri = _GLOBAL.URLBACKEND + '/upload/files/' + id
  var response = request({
    url: uri,
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function countSKDraft(listQuery, myuserpos) {
  
  var search = listQuery.search
  // console.log(search)

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  var data = ''
  var uri = _GLOBAL.URLBACKEND + '/datasurats/count?perihalSurat_contains='+search+'&hruserpositions_createdBy=' + myuserpos + '&statusSurat=11&isNumGenerated=0&eolettertype_ne=1&deleted=0'
  // console.log(uri)

  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function getSKDraft(listQuery, myuserpos) {

  var page = listQuery.page ? listQuery.page : 15
  var limit = listQuery.limit ? listQuery.limit : 1000
  var search = listQuery.search

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var start = (page - 1) * limit
  if (start < 0) start = 0

  var uri = _GLOBAL.URLBACKEND + '/datasurats?perihalSurat_contains='+search+'&hruserpositions_createdBy=' + myuserpos + '&statusSurat=11&isNumGenerated=0&eolettertype_ne=1&deleted=0&_limit=' + limit + '&_start=' + start + '&_sort=id%3ADESC'
  var data = listQuery

  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function doStart_WF(letter_guid, myupos) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var data = { guid: letter_guid, userpos: myupos }
  // console.log(data)
  var uri = _GLOBAL.URLBACKEND + '/datasurats/wf/start'
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'post',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  }).catch((err) => {
    return { 'id': 0, 'data': '', 'status': 'Data should be a unique please recheck the data.' }
  })
  // console.log(response)
  return response
}

export function doApprove_WF(formdata) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var data = formdata
  // console.log(data)
  var uri = _GLOBAL.URLBACKEND + '/datasurats/wf/approve'
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'post',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  }).catch((err) => {
    return { 'id': 0, 'data': '', 'status': 'Data should be a unique please recheck the data.' }
  })
  // console.log(response)
  return response
}

export function doDecline_WF(formdata) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var data = formdata
  // console.log(data)
  var uri = _GLOBAL.URLBACKEND + '/datasurats/wf/decline'
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'post',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  }).catch((err) => {
    return { 'id': 0, 'data': '', 'status': 'Data should be a unique please recheck the data.' }
  })
  // console.log(response)
  return response
}

/** API CaseSurat ==== */

export function getSTFinalCurrYear() {
  var year = moment().format('YYYY')

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var uri = _GLOBAL.URLBACKEND + '/datasurats?eolettertype=19&statusSurat=19&thang=' + year + '&_sort=st_dateStart%3AASC'
  var data = ''
  // console.log(uri)

  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}
